import styled, {createGlobalStyle} from "styled-components";
import ButtonStyle from "@material-ui/core/Button";
import IconButtonStyle from "@material-ui/core/IconButton";
export const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: "Helvetica";
        font-weight: normal;
        src: url('https://thermofisher-chatv2.digitalk.com.br/static/media/HelveticaNeue-Light.2d7491fe.ttf') format('opentype');
    }
    
    @font-face {
        font-family: "Helvetica";
        font-weight: bold;
        src: url('https://thermofisher-chatv2.digitalk.com.br/static/media/HelveticaNeue-Medium.64ab5024.ttf') format('opentype');
    }
    #digitalk-chat {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-family: 'Helvetica', sans-serif!important;
        font-size: 14px;
        z-index: 9999;
        * {
            font-family: 'Helvetica', sans-serif!important;
            z-index: 9999;
            p {
                text-align: start;
            }
        }
    }
    
    
    
    .hidden {
        display: none;
    }
`


export const Button = styled(ButtonStyle)`
    && {
        position: fixed;
        right: 32px;
        bottom: 32px;
        background-color: #E71316;
        color: #ffffff;
        padding: 9px 16px 9px 32px;
        width: unset;
        border-radius: 3px;
        .button_chat {
            position: relative;
            div {
                box-sizing: border-box;
              position: absolute;
              background-color: #dd3a3d;
              border-radius: 50%;
              width: 70px;
              left: -80px;
              height: 70px;
                display: flex;
                justify-content: center;
              border: 5px solid #E71316;
              img {
                height: 50px;
              }
            }
        }
        
        span {
            font-size: 18px;
            text-wrap: none;
            text-transform: none;
            svg {
                margin-right: 11px;
                height: 20px;
            }
        }
    }
`

export const IconButton = styled(IconButtonStyle)`
    && {
        color: #ffffff;
        width: fit-content;
        svg {
            height: 13px;
        }
    }
`

export const Header = styled.div`
    color: #ffffff;
    display: flex;
    align-items: center;
    padding: 7px 5px 7px 17px;
    justify-content: space-between;
    background-color: #E71316;
    background-size: 250px, 100%;
    >div {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-weight: bold;
        font-size: 16px;
        width: calc(100% - 37px);
        p{
            margin: 0;
            &:not(:first-child) {
                color: #222;
                font-size: 12px;
            }
        }
        svg {
            margin-right: 10px;
            height: 20px;
        }
    }
`

export const Container = styled.div`
    width: 320px;
    max-width: 100%;
    max-height: 100%;
    border-radius: 5px;
    overflow: hidden;
    height: 608px;
    position: fixed;
    right: 32px;
    bottom: 32px;
    filter: drop-shadow(0px 30px 40px rgba(0, 0, 0, 0.15));
    @media(max-height: 540px) {
        bottom: 0;
        max-height: 100%;
    }
    @media(max-width: 500px) {
        height: 100vh;
        width: 100vw;
        border-radius: 0;
        right: 0;
        bottom: 0;
    }
`

