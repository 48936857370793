import styled,{keyframes} from 'styled-components'
import ThermofisherBot from 'assets/images/thermofisher-bot.png'
const loadingDelay = keyframes`
    0%, 80%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1.0) }
`
const loading2 = keyframes`
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
`
const loading3 = keyframes`
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
`
const appearLeft = keyframes`
    from {
        opacity: 0;
        left: -5px;
        bottom: -5px;
    }
    to {
        opacity: 1;
        left: 0;
        bottom: 0;
    }
`
const appearRight = keyframes`
    from {
        opacity: 0;
        right: -5px;
        bottom: -5px;
    }
    to {
        opacity: 1;
        right: 0;
        bottom: 0;
    }
`

export const Container = styled.li`
    padding: 7px 8px;
    position: relative;
    max-width: 90%;
    background: #F2F6F7;
    color: #222222;
    border-radius: 2px;
    font-size: 14px;
    overflow-wrap: break-word;
    >svg {
        height: 18px;
        margin-left: 6px;
        color: #122C62;
        cursor: pointer;
    }
    a {
        cursor: pointer;
        width: fit-content;
        &:not(.link) {
            display: flex;
        }
        svg {
            height: 14px;
            margin-left: 10px;
        }
    }
    &.operator {
        align-self: flex-start;
        margin-top: 16px;
        position: relative;
        opacity: 0;
        margin-left: 22px;
        animation: .4s ${appearLeft} .45s forwards;
        &:before {
            content: ' ';
            background: url('https://thermofisher-chatv2.digitalk.com.br/static/media/thermofisher-bot.a10fc1b8.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            position: absolute;
            top: 4px;
            left: -30px;
        }
        &+.operator{
            margin-top: 8px;
            &:before {
                content: unset;
            }
        }
    }
    &.customer {
        align-self: flex-end;
        margin-top: 16px;
        background: #dc3a3d;
        color: #fff;
        opacity: 0;
        position: relative;
        animation: .3s ${appearRight} .1s forwards;
        /*&:after {
            content: ' ';
            width: 8px;
            height: 10px;
            position: absolute;
            top: 0;
            right: -5px;
            border-radius: 0 2px 0 0;
            background: linear-gradient(135deg, #dc3a3d 50%, transparent 50%);
        }*/
        &+.customer{
            margin-top: 8px;
        }

        &.buttons {
            background-color: transparent;
            display: flex;
            &:after {
                background: transparent;
            }
        }
    }
    
    &.isLoading {
        &>div {
            width: 3px;
            height: 3px;
            background-color: #122C62;
            border-radius: 50%;
            display: inline-block;
            animation: ${loadingDelay} 1.4s infinite ease-in-out both;
            &+div{
                margin-left: 3px;
            }
            &:nth-child(1) {
                animation-delay: -0.32s;
            }
            &:nth-child(2) {
                animation-delay: -0.16s;
            }
        }
    }
`