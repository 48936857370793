import {Container} from './style'
import classNames from 'classnames'
import {ReactComponent as DownloadIcon} from "assets/images/icon/download.svg";
import {toast} from 'react-toastify';
import {RadioGroup} from 'component/form/styled'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import React from "react";

const Message = (props) => {
    const handleCopy = (value) => {
        let textField = document.createElement('textarea')
        textField.innerText = value
        document.body.appendChild(textField)
        textField.focus()
        document.execCommand('SelectAll');
        document.execCommand("Copy", false, null);
        textField.remove()
        toast('Copiado com sucesso!', {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        })
    }

    let message = `${props.body}`
    //message = message && message.replace(/(_(.*?)_)/g, match => `<i>${match.slice(1, -1)}</i>`)
    message = message && message.replace(/(```(.*?)```)/g, match => `<font face="monospace">${match.slice(3, -3)}<\/font>`)
    message = message && message.replace(/(\*(.*?)\*)/g, match => `<b>${match.slice(1, -1)}</b>`)
    message = message && message.replace(/(~(.*?)~)/g, match => `<strike>${match.slice(1, -1)}</strike>`)
    message = message && message.replace(/\n/g, "<br />");
    const messageOptions = props.isOptionsNumber && message.split('<br />').length > 1 && message.split('<br />').filter(x => x.length > 0).map(x => {
        let value = x.split('</b> ')
        let number = value[0].match(/\d/g).join('')
        return {
            number,
            label: value[1],
            value: props.options && props.options.find(x => x.label === number).value.input.text
        }
    })
    return !props.isLoading ? (props.isOptionsNumber && messageOptions) ? <Container className={classNames({
            customer: props.source === 'customer',
            operator: props.source !== 'customer',
            isLoading: props.isLoading
        })}>
            <RadioGroup onChange={(e) => {
                e.preventDefault()
                props.onSendOption({
                    label: messageOptions && messageOptions.find(x => e.target.value === x.value).label,
                    value: e.target.value
                })
            }}>
                {messageOptions && messageOptions.map(x => <FormControlLabel key={`option_${x.value}`} classes={{label: 'label_radio'}}
                                                           value={x.value}
                                                           control={<Radio data-label={x.label} size="small"
                                                                           classes={{root: "radio"}} color="secondary"/>}
                                                           label={x.label}/>)}
            </RadioGroup>
        </Container> : ((props.file && props.file.length > 0) ? <Container imgURL={props.imgURL} className={classNames({
            customer: props.source === 'customer',
            operator: props.source !== 'customer',
            isLoading: props.isLoading
        })}><a href={props.file} download target="_blank">
            Download arquivo <DownloadIcon/>
        </a></Container> : <Container imgURL={props.imgURL} className={classNames({
            customer: props.source === 'customer',
            operator: props.source !== 'customer',
            isLoading: props.isLoading
        })} dangerouslySetInnerHTML={{__html: message}}/>) :
        <Container imgURL={props.imgURL} className={classNames({isLoading: props.isLoading}, 'operator')}>
            <div/>
            <div/>
            <div/>
        </Container>
}

Message.defaultProps = {}

export default Message