import ThermoFisherColor from 'assets/images/brands/THERMOFISHER-02.png'
import ThermoFisherWhite from 'assets/images/brands/THERMOFISHER-02.png'

export const Wallet = {
    thermofisher: {
        name: "Thermo Fisher Scientific - BR",
        shortname: "Thermo Fisher Scientific - BR",
        logo_color: ThermoFisherColor,
        logo_white: ThermoFisherWhite,
        token: process.env.REACT_APP_API_PATH === "https://ms-chatv3-prod.digitalk.com.br" ? '3a9326f1e0c10b2bbfb749f6aa4cb8ef' : 'ec3bffc12d837268bb8ea86be53e7bcb',
        color: {
            primary: "#E71316",
            secondary: "#00677F"
        }
    }
}