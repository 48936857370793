import React, {useContext, useEffect, useRef} from 'react'
import {ButtonContainer, Loading, Main, MessageContainer} from './style'
import {Form, RadioGroup} from 'component/form/styled'
import Message from "component/chat/message";
import Input from "component/chat/input";
import WidgetContext from 'component/widget/context'
import {postMessage} from "api/message";
import {toast} from "react-toastify";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

const Widget = () => {
    const {chat} = useContext(WidgetContext)
    const MessagesRef = useRef(null)
    const handleCopy = (value) => {
        let textField = document.createElement('textarea')
        textField.innerText = value
        document.body.appendChild(textField)
        textField.focus()
        document.execCommand('SelectAll');
        document.execCommand("Copy", false, null);
        textField.remove()
        toast('Copiado com sucesso!', {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        })
    }
    const initialMessage = "<b>01.</b> Programa de Desconto \n<b>02.</b> Solicitação sobre preço de medicamento\n<b>03.</b> Onde comprar\n<b>04.</b> Solicitação de Amostra Grátis\n<b>05.</b> Recursos Humanos (envio de CV)\n<b>06.</b> Fornecedores (Exclusivo para Empresas/ Serviços)\n<b>07.</b> Visita de representante\n<b>08.</b> Compra direta\n<b>09.</b> Denúncia\n<b>10.</b> Elogio / Agradecimento / Sugestão\n<b>11.</b> Esclarecimento de dúvidas técnicas\n<b>12.</b> Reclamação\n<b>13.</b> Reporte de evento adverso\n"
    const onSendMessage = async (x) => {
        chat.setChat({
            sending: true
        })

        const formData = new FormData()
        formData.append('message', x.value)
        formData.append('id_protocol', chat.id)

        let message = await postMessage(formData)

        chat.setChat({
            options: [],
            sending: false,
            message: "", file: [], messages: [...chat.messages, {
                source: 'customer',
                body: x.label
            }]
        })
    }

    useEffect(() => {
        if (MessagesRef?.current) {
            MessagesRef.current.scrollTop = MessagesRef.current.scrollHeight;
        }
        /*MessagesRef?.current?.lastElementChild?.scrollIntoView({
            behavior: 'smooth',
            block: "end"
        })*/
    }, MessagesRef.current ? [chat.messages, MessagesRef.current.offsetHeight, chat.options] : [chat.messages, chat.options])

    const isBot = !(chat.messages.map(x => x.source === "operator").filter(x => x).length > 0)
    const isOptionsNumber = Array.isArray(chat.options) && chat.options.length > 0
    console.log(chat)
    const messageOptions = isOptionsNumber && Array.isArray(chat.messages) && JSON.parse(chat.options[0].message)?.options.map((x, i) => ({
        number: i,
        value: x.title,
        label: x.title
    }))

    return <Main>
        {!Array.isArray(chat.messages) || chat.messages.length === 0 ? <Loading>
                Por favor, aguarde alguns instantes...
            </Loading> : <>
        <MessageContainer ref={MessagesRef}>
            {chat.messages.map((x, i) => <Message
                onSendOption={(x) => onSendMessage(x)}
                options={chat.options} key={`message_${i}`} {...x}/>)}
            {isBot && Array.isArray(chat.messages) && chat.messages.length > 0 && (chat.messages[chat.messages.length - 1].source === "customer") &&
            <Message onSendOption={(x) => onSendMessage(x)} isOptionsNumber={isOptionsNumber} options={chat.options}
                     source="operator" isLoading={true}/>}

            {messageOptions && <div className="_options">
                    <RadioGroup classes={{root: 'message_options'}} onChange={(e) => {
                        e.preventDefault()
                        onSendMessage({
                            label: messageOptions.find(x => e.target.value === x.value).label,
                            value: e.target.value
                        })
                    }}>
                        {messageOptions.map(x => <FormControlLabel key={`option_${x.value}`}
                                                                   classes={{label: 'label_radio'}}
                                                                   value={x.value}
                                                                   control={<Radio data-label={x.label} size="small"
                                                                                   classes={{root: "radio"}}
                                                                                   color="secondary"/>}
                                                                   label={x.label}/>)}
                    </RadioGroup>
                </div>}
        </MessageContainer>

        {(chat.closed ? <ButtonContainer className="center">
            <p>O seu chat foi finalizado</p>
            <button onClick={() => document.location.reload(true)}>Iniciar novo chat</button>
        </ButtonContainer> : !isOptionsNumber && (Array.isArray(chat.messages) && chat.messages.length > 0 && chat.messages[chat.messages.length - 1].response_type === 'text' &&
            <Input/>))}
            </>
        }
    </Main>
}

export default Widget