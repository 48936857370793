import React, {useEffect, useState} from 'react'
import {Container, Form, ButtonContainer, Button, Box, InputGroup, RadioGroup, FormControl, OutlinedInput, InputLabel, FormHelperText, Select, FormLabel} from "./styled";
import InputMask from 'react-input-mask';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import useStateCallback from 'utils/useStateCallback'
import {ReactComponent as ArrowUpIcon} from "assets/images/icon/arrow-up.svg"
import WidgetComponent from "../widget"
import {MessageContainer} from '../chat/style'
import {Container as Message} from '../chat/message/style'
const FormInitState = {
    name: {value: '', error: ''},
    phone: {value: '', error: ''},
    email: {value: '', error: ''},
    customer_cpfcnpj: {value: '', error: ''},
    state: {value: '', error: ''},
    department: {value: null, error: ''},
}

const states = ["AC", "AL", "AM", "AP", "BA", "CE", "DF", "ES", "GO", "MA", "MT", "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN", "RO", "RS", "RR", "SC", "SE", "SP", "TO"]
const type = [{label: "Consumidor", id: 1}, {label: "Farmácia", id: 2}, {label: "Farmacêutico", id: 7}, {label: "Médico", id: 4}, {label: "Hospital", id: 8}, {label: "Representante", id: 9}, {label: "Profissional da saúde", id: 5}, {label: "Distribuidor", id: 3}, {label: "Empresa pública/privada", id: 6}, {label: "Outros", id: 10}]

const AccessForm = (props) => {
    const [terms, setTerms] = useState(null)
    const termsMessage = [
        <p>Olá, seja bem-vindo (a)! Eu sou a TAS, a assistente virtual da Thermo Fisher Scientific.</p>,
        <p>Nesse momento eu posso ajudar com dúvidas sobre qPCR/PCR e extração de DNA e RNA.</p>,

    ]
    const [form, setForm] = useStateCallback({...FormInitState})
    const [chat, setChat] = useState(false)

    const handleChange = (e) => {
        const {value, name, type} = e.target
        if (name === "department") {
            setForm({...FormInitState})
        }
        switch (name) {
            case "department":
                setForm(prevState => ({
                    ...FormInitState,
                    [name]: {...prevState[name], value: Number.isInteger(value) ? Number(value) : value}
                }))
                break
            default:
                setForm(prevState => ({
                    ...prevState,
                    [name]: type === "checkbox" ? {
                        ...prevState[name],
                        value: !prevState[name].value
                    } : {...prevState[name], value}
                }))
                break;
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setForm(prevState => ({
            ...prevState,
            name: {...prevState.name, error: form.name.value.length === 0 ? "Campo obrigatório" : ""},
            phone: {...prevState.phone, error: form.phone.value.length === 0 ? "Campo obrigatório" : ""},
            email: {...prevState.email, error: form.email.value.length === 0 ? "Campo obrigatório" : ""}
        }), (currForm) => {
            const value = Object.keys(currForm).reduce((prev, curr) => {
                prev[curr] = typeof currForm[curr].value === 'string' ? currForm[curr].value.trim() : currForm[curr].value
                !prev.error && (prev.error = currForm[curr].error.length > 0)
                return prev
            }, {})

            if (!value.error) {
                delete value.error
                setChat(value)
            }
        })
    }

    const getWelcome = () => {
        const hour = new Date().getHours();
        return ((hour<12 && "Bom dia") || (hour<18 && "Boa tarde") || "Boa noite")
    }

    const [timeLeft, setTimeLeft] = useState(6);

    useEffect(() => {
        if (!timeLeft) return;

        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1500);

        return () => clearInterval(intervalId);
    }, [timeLeft]);

    return !chat ? <Container>
       {typeof terms !== "boolean" ? <>
            <MessageContainer>
                {timeLeft <= 6 && <Message className='operator'>
                    Olá, seja bem-vindo (a)! Eu sou a TAS, a assistente virtual da Thermo Fisher Scientific.
                </Message>}
                {timeLeft <= 5 && <Message className='operator'>
                    Nesse momento eu posso ajudar com dúvidas sobre qPCR/PCR, extração de DNA/RNA e Análise de Proteínas/Anticorpos. As informações são atualizadas periodicamente.
                </Message>}
                {timeLeft <= 4 && <Message className='operator'>
                    Para outras aplicações, por favor envie um e-mail para os meus colegas do suporte em <a className='link' href="mailto:suporte.br@thermofisher.com">suporte.br@thermofisher.com</a> (ou ligue <a className='link' href="tel:08007725433">0800 7725433</a>, opção 2 e 1).
                </Message>}
                {timeLeft <= 3 && <Message className='operator'>
                    Durante o contato alguns dados pessoais serão coletados.
                    <br/>
                    Ao utilizar a ferramenta você concorda com a política de privacidade da Thermo Fisher Scientific (<a className='link' href="https://www.thermofisher.com/br/en/home/global/privacy-policy.html" target="_blank">https://www.thermofisher.com/br/en/home/global/privacy-policy.html</a>)
                </Message>}
                {timeLeft <= 2 && <Message className="operator">
                    <b>Você aceita os termos?</b>
                    </Message>}
                {timeLeft <= 1 && <Message className="customer buttons">

                    <Button type="button" className="message" onClick={() => props.handleClose()}>Não aceito</Button>
                    <Button type="button" className="message" onClick={() => setTerms(true)}>Aceito</Button>
                    </Message>}
            </MessageContainer>
        </> : (!terms ? <>
            <Box>
                <p className="title">Infelizmente não posso dar continuidade no atendimento.</p>
                <p>Para mais informações entre em contato</p>
            </Box>
            <Button type="button" className="back" onClick={() => {
                setTerms(null)
                setTimeLeft(6)
            }}>Voltar</Button>
        </> : <>
            <Form onSubmit={handleSubmit} className="form_container">
                <div>
                    <Button type="button" className="backDepartment" onClick={() => {
                        setTerms(null)
                        setTimeLeft(6)
                    }}><ArrowUpIcon/>Voltar</Button>
                    <FormControl color="secondary">
                        <InputLabel shrink={true} error={form.name.error.trim() !== ''} htmlFor="name">Nome</InputLabel>
                        <OutlinedInput name="name"  id="name" placeholder="Seu nome" error={form.name.error.trim() !== ''} onChange={handleChange} value={form.name.value}/>
                        <FormHelperText error={form.name.error.trim() !== ''}>{form.name.error}</FormHelperText>
                    </FormControl>
                    <FormControl className="phone" color="secondary">
                        <InputLabel shrink={true} error={form.phone.error.trim() !== ''} htmlFor="phone">Telefone</InputLabel>
                        <InputMask
                            mask={form.phone.value.match(/\d/g) && form.phone.value.match(/\d/g).join('').length === 11 ? "(99) 99999-9999" : "(99) 9999-99999"}
                            value={form.phone.value}
                            error={form.phone.error.trim() !== ''}
                            helperText={form.phone.error.trim() !== ''}
                            maskChar={form.phone.value.match(/\d/g) && form.phone.value.match(/\d/g).join('').length === 10 ? "" : " "}
                            onChange={handleChange}
                            name='phone'>
                            {() => <OutlinedInput name="phone" id="phone" placeholder="(  )      -    "/>}
                        </InputMask>
                        <FormHelperText error={form.phone.error.trim() !== ''}>{form.phone.error}</FormHelperText>
                    </FormControl>
                    <FormControl color="secondary">
                        <InputLabel shrink={true} error={form.email.error.trim() !== ''} htmlFor="email">E-mail</InputLabel>
                        <OutlinedInput name="email" id="email" placeholder="email@email.com" error={form.email.error.trim() !== ''} onChange={handleChange} value={form.email.value}/>
                        <FormHelperText error={form.email.error.trim() !== ''}>{form.email.error}</FormHelperText>
                    </FormControl>
                </div>
                <Button disabled={form.email.value.trim() === '' || form.phone.value.trim() === '' || form.name.value.trim() === ''} className="confirm" type="submit">INICIAR CONVERSA</Button>
            </Form>
        </>)}
    </Container> : <WidgetComponent form={chat} {...props}/>

}

export default AccessForm